<template>
    <div class="MyYearWorkReport">
        <van-tabs v-model="activeTab" safe-area-inset-bottom>
            <van-tab title="我的年报">
                <van-empty v-show="myYearWorkReportList.length==0 && !loading" description="您本年还未填写年报哦!" />
                <van-list v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad">
                    <van-cell v-for="item in myYearWorkReportList" :key="item.ID" @click="edit(item)">
                        <template #title>
                            {{item.YearDay}}年报
                        </template>
                        <template #label>
                            <p>
                                年终总结:<br />{{item.WorkThisYear}}
                            </p>
                            <p v-show="item.Contribution">
                                年度贡献:<br />{{item.Contribution}}
                            </p>
                            <p v-show="item.Proposal">
                                给公司的建议:<br />{{item.Proposal}}
                            </p>
                            <p v-show="item.WorkNextYear">
                                明年工作计划:<br />{{item.WorkNextYear}}
                            </p>
                        </template>
                    </van-cell>
                </van-list>
                <van-tabbar v-model="active" safe-area-inset-bottom placeholder>
                    <van-tabbar-item v-if="myYearWorkReportList.length==0" @click="isEdit=false;dialogFormVisible=true">填写年报</van-tabbar-item>
                </van-tabbar>
            </van-tab>
            <van-tab title="团队年报">
                <van-empty v-show="groupYearWorkReportList.length==0 && !loading2" description="您的团队本年尚未填写年报哦!" />
                <van-list v-model="loading2"
                          :finished="finished2"
                          finished-text="没有更多了"
                          @load="onLoad2">
                    <van-cell v-for="(item,i) in groupYearWorkReportList" :key="item.ID">
                        <template #title>
                            {{i+1}},{{item.CreateUser}}({{item.YearDay}})年报
                        </template>
                        <template #label>
                            <p>
                                年终总结:<br />{{item.WorkThisYear}}
                            </p>
                            <p v-show="item.Contribution">
                                年度贡献:<br />{{item.Contribution}}
                            </p>
                            <p v-show="item.Proposal">
                                给公司的建议:<br />{{item.Proposal}}
                            </p>
                            <p v-show="item.WorkNextYear">
                                明年工作计划:<br />{{item.WorkNextYear}}
                            </p>
                        </template>
                    </van-cell>
                </van-list>
                <van-tabbar v-model="activeGroup" safe-area-inset-bottom placeholder>
                    <van-tabbar-item @click="prevYear">上一年</van-tabbar-item>
                    <van-tabbar-item @click="currYear">本年</van-tabbar-item>
                    <van-tabbar-item @click="nextYear">下一年</van-tabbar-item>
                </van-tabbar>
            </van-tab>
        </van-tabs>
        <van-popup v-model="dialogFormVisible" position="bottom" :style="{ height: '70%' }" safe-area-inset-bottom>
            <van-form @submit="onSubmit">
                <div class="add_title">{{isEdit?'调整':'新增'}}年报</div>
                <van-field v-model="addForm.WorkThisYear"
                           rows="3"
                           autosize
                           label="年终总结"
                           type="textarea"
                           maxlength="500"
                           placeholder="年终总结" :rules="[{ required: true, message: '年终总结必须填写哦!' }]"
                           show-word-limit />
                <van-field v-model="addForm.Contribution"
                           rows="3"
                           autosize
                           label="年度贡献"
                           type="textarea"
                           maxlength="500"
                           placeholder="年度贡献"
                           show-word-limit />
                <van-field v-model="addForm.Proposal"
                           rows="3"
                           autosize
                           label="给公司的建议"
                           type="textarea"
                           maxlength="500"
                           placeholder="给公司的建议"
                           show-word-limit />
                <van-field v-model="addForm.WorkNextYear" v-if="showNextYearPlan"
                           rows="3"
                           autosize
                           label="明年工作计划"
                           type="textarea"
                           maxlength="500"
                           placeholder="明年工作计划"
                           show-word-limit />
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>

        </van-popup>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    export default {
        name: 'MyWorkThisYearReport',
        components: {
        },
        data() {
            return {
                isEdit: false,
                showNextYearPlan: false,
                queryForm: {
                    PageIndex: 1,
                    PageSize: 6,
                    Data: {
                        CreateUser: '',
                        YearDay: 0,
                    }
                },
                queryForm2: {
                    PageIndex: 1,
                    PageSize: 6,
                    Data: {
                        CreateUser: '',
                        YearDay: '',
                    }
                },
                addForm: {
                    ID: 0,
                    WorkThisYear: '',
                    WorkNextYear: '',
                    Question: '',
                    Contribution: '',
                    Proposal: ''
                },
                activeTab: 0,
                active: 0,
                activeGroup: 1,
                dialogFormVisible: false,
                loading: false,
                loading2: false,
                finished: false,
                finished2: false,
                myYearWorkReportList: [],
                groupYearWorkReportList: [],
            };
        },
        methods: {
            getYear(theDate) {
                theDate = theDate || new Date();
                let firstDateOfYear = theDate;
                let time = new Date().setDate(4);
                time = new Date(time).setMonth(0);
                if (firstDateOfYear < time) {
                    return firstDateOfYear.getFullYear() - 1;
                }
                return firstDateOfYear.getFullYear();
            },
            onLoad(reload) {

                if (reload) {
                    this.queryForm.PageIndex = 1;
                }
                this.queryForm.Data.CreateUser = this.user.UserName;
                this.$post(this.apiUrl.getYearWorkReportList, this.queryForm).then((d) => {

                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        if (reload) {
                            this.myYearWorkReportList = [];
                        }
                        //this.formatString(d.Data);
                        d.Data.Rows.map(r => this.myYearWorkReportList.push(r));
                    }

                    this.loading = false;

                    if (this.myYearWorkReportList.length >= d.Data.TotalCounts) {

                        this.finished = true;
                    }

                    this.queryForm.PageIndex++;

                });

            },
            onLoad2(reload) {
                if (reload) {
                    this.queryForm2.PageIndex = 1;
                    this.finished2 = false;
                }
                this.queryForm2.Data.CreateUser = '';
                this.$post(this.apiUrl.getYearWorkReportList, this.queryForm2).then((d) => {

                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        if (reload) {
                            this.groupYearWorkReportList = [];
                        }
                        d.Data.Rows.map(r => this.groupYearWorkReportList.push(r));
                    }

                    this.loading2 = false;

                    if (this.groupYearWorkReportList.length >= d.Data.TotalCounts) {

                        this.finished2 = true;
                    }

                    this.queryForm2.PageIndex++;

                });
            },
            onSubmit() {
                let that = this;
                this.$post(that.isEdit ? that.apiUrl.editYearWorkReport : that.apiUrl.addYearWorkReport, that.addForm).then((d) => {
                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        Toast.success('提交成功!');
                        that.dialogFormVisible = false;
                        that.onLoad(true);
                    }
                });
            },
            edit(row) {

                if (row.YearDay == this.getYear()) {
                    this.isEdit = true;
                    this.addForm.ID = row.ID;
                    this.addForm.WorkThisYear = row.WorkThisYear;
                    this.addForm.WorkNextYear = row.WorkNextYear;
                    this.addForm.Contribution = row.Contribution;
                    this.addForm.Proposal = row.Proposal;
                    this.addForm.Question = row.Question;
                    this.dialogFormVisible = true;
                }
            },
            prevYear() {
                let d = this.queryForm2.Data.YearDay - 1;
                this.queryForm2.Data.YearDay = d;
                this.onLoad2(true);
            },
            currYear() {
                this.queryForm2.Data.YearDay = this.getYear();
                this.onLoad2(true);
            },
            nextYear() {
                let d = this.queryForm2.Data.YearDay + 1;
                this.queryForm2.Data.YearDay = d;
                this.onLoad2(true);
            }
        },
        mounted() {
            this.queryForm.Data.CreateUser = this.user.UserName;
            this.showNextYearPlan = true;
            this.queryForm.Data.YearDay = this.getYear();
            this.queryForm2.Data.YearDay = this.getYear();

            window.$vue = this;
        }

    }
</script>

<style scoped>

    .MyYearWorkReport {
        height: 100%;
    }

        .MyYearWorkReport p {
            white-space: pre-wrap;
        }

    .van-tabs {
        height: 100%;
    }

    .add_title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        padding: 15px;
    }

    .MyYearWorkReport /deep/ .van-tabs__content {
        max-height: calc(100% - 44px);
        overflow-y: auto;
    }
</style>