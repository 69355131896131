<template>
    <div class="MyTask">
        <van-tabs v-model="activeTab" safe-area-inset-bottom>
            <van-tab title="我的任务">
                <van-empty v-show="myTaskList.length==0 && !loading" description="您今日没有安排任务哦!" />
                <van-list v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad">
                    <van-cell v-for="item in myTaskList" :key="item.ID" @click="edit(item)" :class="(item.CompletionTime && new Date(item.CompletionTime.replace(/-/g, '/')) || new Date())>new Date(item.RequireTime.replace(/-/g, '/')) && '延期'|| item.TaskState">
                        <template #title>
                            （{{item.TaskType}}）{{item.TaskName}}
                        </template>
                        <template #label>
                            <p>
                                任务状态:{{item.TaskState}}
                            </p>
                            <p>
                                要求完成日期:{{item.RequireTime}}
                            </p>
                            <p v-if="item.CompletionTime">
                                实际完成日期:{{item.CompletionTime}}
                            </p>
                            <p>
                                进度及说明:{{item.Progress&&item.Progress+'%'||'未填写进度'}}<span v-if="item.ProgressDesc">（{{item.ProgressDesc}}）</span>
                            </p>
                        </template>
                    </van-cell>
                </van-list>
            </van-tab>
            <van-tab title="任务管理">
                <van-empty v-show="groupTaskList.length==0 && !loading2" description="您的团队今日还没有工作安排哦!" />
                <van-list v-model="loading2"
                          :finished="finished2"
                          finished-text="没有更多了"
                          @load="onLoad2">
                    <van-cell v-for="item in groupTaskList" :key="item.ID" @click="edit(item)" :class="(item.CompletionTime && new Date(item.CompletionTime.replace(/-/g, '/')) || new Date())>new Date(item.RequireTime.replace(/-/g, '/')) && '延期'|| item.TaskState">
                        <template #title>
                            （{{item.FollowUpUser}}-{{item.TaskType}}）{{item.TaskName}}
                        </template>
                        <template #label>
                            <p>
                                任务状态:{{item.TaskState}}
                            </p>
                            <p>
                                要求完成日期:{{item.RequireTime}}
                            </p>
                            <p v-if="item.CompletionTime">
                                实际完成日期:{{item.CompletionTime}}
                            </p>
                            <p>
                                进度及说明:{{item.Progress&&item.Progress+'%'||'未填写进度'}}<span v-if="item.ProgressDesc">（{{item.ProgressDesc}}）</span>
                            </p>
                        </template>
                    </van-cell>
                </van-list>
                <van-tabbar v-model="active" safe-area-inset-bottom placeholder>
                    <van-tabbar-item @click="add">指派新任务</van-tabbar-item>
                </van-tabbar>
            </van-tab>
        </van-tabs>
        <van-popup v-model="dialogFormVisible" position="bottom" :style="{ height: '80%' }" safe-area-inset-bottom>
            <van-form @submit="onSubmit">
                <div class="add_title">{{(isEdit)?'编辑':'新增'}}任务信息</div>
                <van-field v-model="addForm.ProjectName" label="所属项目" placeholder="点击选择项目" readonly clickable @click="showProjectPicker = true"
                           :rules="[{ required: true, message: '所属项目必填填写' }]">
                </van-field>
                <van-field name="TaskNature" label="任务性质">
                    <template #input>
                        <van-radio-group v-model="addForm.TaskNature" direction="horizontal">
                            <van-radio name="日常工作">日常工作</van-radio>
                            <van-radio name="现场问题">现场问题</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field v-model="addForm.TaskName" label="任务名称" placeholder="填写任务名称" :rules="[{ required: true, message: '任务名称必填填写' }]">
                </van-field>
                <van-field name="TaskType" label="任务类型">
                    <template #input>
                        <van-radio-group v-model="addForm.TaskType" direction="horizontal">
                            <van-radio v-for="(item,i) in taskTypes" :key="i" :name="item.k">{{item.v}}</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field name="Level" label="任务级别">
                    <template #input>
                        <van-radio-group v-model="addForm.Level" direction="horizontal">
                            <van-radio name="一级">一级</van-radio>
                            <van-radio name="二级">二级</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field name="ThePriority" label="优先级">
                    <template #input>
                        <van-radio-group v-model="addForm.ThePriority" direction="horizontal">
                            <van-radio name="低">低</van-radio>
                            <van-radio name="中">中</van-radio>
                            <van-radio name="高">高</van-radio>
                            <van-radio name="紧急">紧急</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                <van-field v-model="addForm.FollowUpUserName" label="跟进人" placeholder="点击选择跟进人" readonly clickable @click="showUserPicker = true"
                           :rules="[{ required: true, message: '跟进人必填' }]">
                </van-field>
                <van-field readonly
                           clickable
                           name="datetimePicker"
                           v-model="addForm.RequireTime"
                           label="要求完成时间"
                           placeholder="点击选择时间"
                           @click="showTimePicker = true" />

                <van-field v-model="addForm.TaskDesc"
                           rows="3"
                           autosize
                           label="任务描述"
                           type="textarea"
                           maxlength="500"
                           placeholder="任务补充说明,非必填"
                           show-word-limit />
                <van-field label="附件图片">
                    <template #input>
                        <van-uploader v-model="addForm.Files" :after-read="uploadImgs" />
                    </template>
                </van-field>

                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>
            <van-popup v-model="showProjectPicker" position="bottom" :style="{ height: '40%' }" safe-area-inset-bottom>
                <van-picker show-toolbar
                            :columns="projectList"
                            value-key="ProjectName"
                            @confirm="onConfirmPicker($event,'ProjectID','ProjectName')"
                            @cancel="showProjectPicker = false" />
            </van-popup>
            <van-popup v-model="showUserPicker" position="bottom" :style="{ height: '40%' }" safe-area-inset-bottom>
                <van-picker show-toolbar
                            :columns="userList"
                            value-key="DisplayName"
                            @confirm="onConfirmPicker($event,'FollowUpUser','FollowUpUserName')"
                            @cancel="showUserPicker = false" />
            </van-popup>
            <van-popup v-model="showTimePicker" position="bottom">
                <van-datetime-picker type="datetime" v-model="addForm.RequireTime2"
                                     @confirm="onConfirmTime"
                                     @cancel="showTimePicker = false" />
            </van-popup>
        </van-popup>

        <van-popup v-model="dialogReviewVisible" position="bottom" :style="{ height: '80%' }" safe-area-inset-bottom>
            <van-form readonly>
                <div class="add_title">查看任务信息</div>

                <van-field v-model="addForm.ProjectName" label="所属项目" autosize type="textarea" show-word-limit />
                <van-field v-model="addForm.TaskNature" label="任务性质" autosize type="textarea" show-word-limit />
                <van-field v-model="addForm.TaskName" label="任务名称" autosize type="textarea" show-word-limit />
                <van-field v-model="addForm.TaskTypeName" label="任务类型" autosize type="textarea" show-word-limit />
                <van-field v-model="addForm.Level" label="任务级别" autosize type="textarea" show-word-limit />
                <van-field v-model="addForm.ThePriority" label="优先级" autosize type="textarea" show-word-limit />
                <van-field v-model="addForm.RequireTime" label="要求完成时间" autosize type="textarea" show-word-limit />
                <van-field v-model="addForm.FollowUpUserName" label="跟进人" autosize type="textarea" show-word-limit />
                <van-field v-model="addForm.TaskDesc" label="任务描述" autosize type="textarea" show-word-limit />
                <van-field label="附件图片">
                    <template #input>
                        <van-uploader :show-upload="false" :deletable="false" v-model="addForm.Files" :after-read="uploadImgs" />
                    </template>
                </van-field>

                <div class="btns">
                    <van-button @click="beginTask(row)" v-if="row.TaskState=='未开始'" type="primary">开始任务</van-button>
                    <!--<van-button @click="transferTask(row)" v-if="row.TaskState=='未开始'" type="danger">转办</van-button>-->
                    <van-button @click="remarkTask(row)" v-if="row.TaskState=='进行中'" type="info">进度跟进</van-button>
                </div>
            </van-form>
        </van-popup>
        <!--<van-dialog v-model="showTransferDialog" title="任务转办" @confirm="transferTask" show-cancel-button>
            <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" />
        </van-dialog>-->
        <van-dialog v-model="showRemarkDialog" title="进度跟进" @confirm="remarkTask" show-cancel-button>
            <van-form>
                <van-field label="整体进度">
                    <template #input>
                        <van-slider v-model="progressForm.Progress">
                            <template #button>
                                <div class="custom-button">{{ progressForm.Progress }}</div>
                            </template>
                        </van-slider>
                    </template>
                </van-field>
                <van-field v-model="progressForm.ProgressDesc" label="进度说明"  placeholder="进度补充说明,非必填" autosize type="textarea" show-word-limit />
            </van-form>
        </van-dialog>
    </div>
</template>

<script>    
    import { Toast } from 'vant';
    export default {
        name: 'MyTask',
        components: {
        },
        data() {
            return {
                showProjectPicker: false,
                showUserPicker: false,
                showTransferDialog: false,
                showRemarkDialog: false,
                showTimePicker: false,
                dialogFormVisible: false,
                dialogReviewVisible: false,
                myTaskList: [],
                groupTaskList: [],
                isEdit: false,
                queryForm: {
                    PageIndex: 1,
                    PageSize: 10,
                    Data: {
                        FollowUpUser: '',
                        Date: [],
                    }
                },
                queryForm2: {
                    PageIndex: 1,
                    PageSize: 10,
                    Data: {
                        Date: [],
                    }
                },
                addForm: {
                    ID: 0,
                    ProjectID: null,
                    ProjectName: null,
                    TaskType: 1,
                    TaskTypeName: '',
                    TaskName: '',
                    TaskDesc: '',
                    Level: '二级',
                    FollowUpUser: '',
                    FollowUpUserName: '',
                    ThePriority: '中',
                    RequireTime: new Date().format('yyyy-MM-dd 23:59:59'),
                    RequireTime2: new Date(new Date().format('yyyy/MM/dd 23:59:59')),
                    TaskNature: '日常工作',
                    Imgs: [],
                    Files: [],
                },
                addForm2: {
                    ID: 0,
                    ProjectID: null,
                    ProjectName: null,
                    TaskType: 1,
                    TaskTypeName: '',
                    TaskName: '',
                    TaskDesc: '',
                    Level: '二级',
                    FollowUpUser: '',
                    FollowUpUserName: '',
                    ThePriority: '中',
                    RequireTime: new Date().format('yyyy-MM-dd 23:59:59'),
                    RequireTime2: new Date(new Date().format('yyyy/MM/dd 23:59:59')),
                    TaskNature: '日常工作',
                    Imgs: [],
                    Files: [],
                },
                activeTab: 0,
                active: 0,
                loading: false,
                loading2: false,
                finished: false,
                finished2: false,
                userList: [],
                projectList: [],
                taskTypes: [{ k: 1, v: '电气' }, { k: 2, v: '信息化' }, { k: 3, v: '机器人' }, { k: 4, v: '机械' }, { k: 5, v: '项目' }, { k: 6, v: '销售' }, { k: 7, v: '其他' }],
                row: {},
                beginForm: {
                    ID: 0,
                    ProjectName: '',
                    TaskName: '',
                    PlanCompletionTime: '',
                    PlanScheme: '',
                },
                progressForm: {
                    ID: 0,
                    ProjectName: '',
                    TaskName: '',
                    Progress: 100,
                    MinProgress: 0,
                    ProgressDesc: '',
                },
                transferForm: {
                    ID: 0,
                    ProjectName: '',
                    TaskName: '',
                    NewFollowUpUser: '',
                },
            };
        },
        methods: {
            initData() {
                this.$post(this.apiUrl.getUserList, { PageIndex: 1, PageSize: 9999, Data: {} }).then((d) => {
                    if (d.IsError) {
                        Toast.fail('初始化用户出错!');
                    } else {
                        this.userList = d.Data.Rows;
                    }
                });
                this.$post(this.apiUrl.getProjectList2, { PageIndex: 1, PageSize: 9999, Data: {} }).then((d) => {
                    if (d.IsError) {
                        Toast.fail('初始化项目出错!');
                    } else {
                        this.projectList = d.Data.Rows;
                    }
                });

            },
            uploadImgs(img) {
                let that = this;
                img.status = 'uploading';
                img.message = '上传中';

                this.$post(this.apiUrl.imgUploadUrlBase64, { fileName: img.file.name, base64: img.content }).then(d => {
                    if (d.IsError) {
                        img.status = 'failed';
                        img.message = '上传失败';
                        Toast.fail(d.Msg);

                    } else {
                        img.status = 'done';
                        img.message = '';
                        that.addForm.Imgs.push(d.Data);
                    }

                });




            },
            onConfirmTime(time) {

                this.addForm.RequireTime = time.format('yyyy-MM-dd hh:mm:59');
                this.showTimePicker = false;
            },
            onConfirmPicker(o, k, t) {

                this.addForm[k] = (o instanceof Object) && o.UserName || o.k || o.ID || o;
                this.addForm[t] = (o instanceof Object) && o.DisplayName || o.v || o[t] || o;
                this.showProjectPicker = false;
                this.showUserPicker = false;

            },
            onLoad(reload) {

                if (reload) {
                    this.queryForm.PageIndex = 1;
                }
                this.queryForm.Data.FollowUpUser = this.user.UserName;
                let beginDate = new Date();
                beginDate.setDate(-30);
                this.queryForm.Data.Date[0] = beginDate.format('yyyy-MM-dd')
                this.queryForm.Data.Date[1] = new Date().format('yyyy-MM-dd')
                this.$post(this.apiUrl.getTaskList, this.queryForm).then((d) => {

                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        if (reload) {
                            this.myTaskList = [];
                        }
                        d.Data.Rows.map(r => {
                            if (r.Imgs) {
                                r.Imgs = r.Imgs.split(',').map(r => `${this.globalURL}imgs/${r}`);
                            } else {
                                r.Imgs = [];
                            }
                            r.exp = [];
                        });
                        d.Data.Rows.map(r => this.myTaskList.push(r));
                    }

                    this.loading = false;

                    if (this.myTaskList.length >= d.Data.TotalCounts) {

                        this.finished = true;
                    }

                    this.queryForm.PageIndex++;

                });

            },
            onLoad2(reload) {
                if (reload) {
                    this.queryForm2.PageIndex = 1;
                }

                let beginDate = new Date();
                beginDate.setDate(-6);
                this.queryForm2.Data.Date[0] = beginDate.format('yyyy-MM-dd')
                this.queryForm2.Data.Date[1] = new Date().format('yyyy-MM-dd')
                this.$post(this.apiUrl.getTaskList, this.queryForm2).then((d) => {

                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        if (reload) {
                            this.groupTaskList = [];
                        }
                        d.Data.Rows.map(r => {
                            if (r.Imgs) {
                                r.Imgs = r.Imgs.split(',').map(r => `${this.globalURL}imgs/${r}`);
                            } else {
                                r.Imgs = [];
                            }
                            r.exp = [];
                        });
                        d.Data.Rows.map(r => this.groupTaskList.push(r));
                    }

                    this.loading2 = false;

                    if (this.groupTaskList.length >= d.Data.TotalCounts) {

                        this.finished2 = true;
                    }

                    this.queryForm2.PageIndex++;

                });
            },
            onSubmit() {
                let that = this;
                this.$post(that.isEdit ? that.apiUrl.editTask : that.apiUrl.addTask, that.addForm).then((d) => {
                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        Toast.success('提交成功!');
                        that.dialogFormVisible = false;
                        that.onLoad(true);
                    }
                });
            },
            add() {
                this.isEdit = false;
                Object.assign(this.addForm, this.addForm2);
                this.dialogFormVisible = true;

            },
            edit(row) {
                let that = this;
                that.row = row;
                that.isEdit = row.CreateUser == that.user.UserName && row.TaskState == '未开始';
                //Object.assign(that.addForm, row);
                for (let k in that.addForm) {

                    if (row[k] != undefined) {
                        that.addForm[k] = row[k];
                    }
                }

                delete that.addForm.TaskState;
                that.addForm.TaskTypeName = that.addForm.TaskType
                that.addForm.TaskType = that.taskTypes.find(r => r.v == that.addForm.TaskType).k;
                that.addForm.FollowUpUserName = that.addForm.FollowUpUser;
                that.addForm.FollowUpUser = that.userList.find(r => r.DisplayName == that.addForm.FollowUpUserName).UserName;
                that.addForm.Files = row.Imgs.map(r => { return { url: r, isImage: true } });
                that.addForm.ProjectID = that.projectList.find(r => r.ProjectName == that.addForm.ProjectName).ID;

                if (that.isEdit) {
                    that.dialogFormVisible = true;
                } else {
                    that.dialogReviewVisible = true;

                }
            },
            //开始
            beginTask(row) {
                let that = this;

                that.beginForm.ID = row.ID;
                that.beginForm.PlanCompletionTime = row.RequireTime;

                that.$dialog.confirm({
                    message: '确认开始当前任务吗?',
                }).then(() => {
                    that.$post(that.apiUrl.beginTask, that.beginForm).then((d) => {
                        if (d.IsError) {
                            Toast.fail(d.ErrorMessage);
                        } else {
                            Toast.success('任务已标记为开始!');
                            that.dialogReviewVisible = false;
                            that.onLoad(true);
                        }
                    });
                })


            },
            //转办
            transferTask(row) {

            },
            //填写进度
            remarkTask(row) {
                let that = this;
                if (!that.showRemarkDialog) {
                    that.progressForm.ID = row.ID;
                    that.showRemarkDialog = true;
                    that.progressForm.MinProgress = row.Progress || 0;
                    return;
                }
                that.$post(that.apiUrl.progressTask, that.progressForm).then((d) => {
                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        Toast.success('进度已更新!');
                        that.dialogReviewVisible = false;
                        that.onLoad(true);
                    }
                });
            },
        },
        mounted() {
            this.initData();
            this.queryForm.Data.FollowUpUser = this.user.UserName;
            let begin = new Date().format('yyyy-MM-01 00:00:00');
            let end = new Date(begin);
            end.setMonth(end.getMonth() + 1);
            end.setSeconds(-1);
            this.queryForm.Data.Date = [begin, end.format('yyyy-MM-dd 23:59:59')];
            window.$vue = this;
        }

    }
</script>
<style scoped lang="scss">
    ::v-deep.MyTask {
        height: 100%;

        p {
            white-space: pre-wrap;
        }

        .van-tabs {
            height: 100%;
        }

        .add_title {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            padding: 15px;
        }

        .van-tabs__content {
            max-height: calc(100% - 44px);
            overflow-y: auto;
        }

        .van-list {

            .未开始 {
                background-color: #FFCCCC70;
            }

            .延期 {
                background-color: #FFFFCC70;
            }

            .进行中 {
                background-color: #99FFCC70;
            }

            .已完成 {
                background-color: #CCFFFF70;
            }

            .搁置 {
                background-color: #FFFFCC70;
            }
        }


        .btns {
            margin: 16px;
            text-align: center;

            .van-button {
                margin-right: 8px;
                min-width: 6rem;
            }

            .van-button:last-child {
                margin-right: 0px;
            }
        }

        .custom-button {
            width: 26px;
            color: #fff;
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            background-color: #ee0a24;
            border-radius: 100px;
        }
    }
</style>