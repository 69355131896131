<template>
    <div>
        <van-search v-model="value" placeholder="请输入搜索关键词" />
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="item in bulletinList" :key="item.id">
                <div class="van-multi-ellipsis--l2">
                    {{item.text}}
                </div>
            </van-swipe-item>
        </van-swipe>
        <van-cell-group>
            <template #title>
                <span class="custom-title">我的</span>
            </template>
            <van-grid :border="false" square>
                <van-grid-item v-if="wxurl" icon="bookmark-o" :url="wxurl" text="绑定微信" />
                <van-grid-item icon="bookmark-o" to="/app/myWorkReport" text="我的日报" />
                <van-grid-item icon="bookmark-o" to="/app/myWorkThisWeekReport" text="我的周报" />
                <van-grid-item icon="notes-o" to="/app/myTask" text="我的任务" />
                <van-grid-item icon="notes-o" to="/app/myWorkThisYearReport" text="我的年报" />
            </van-grid>
        </van-cell-group>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    export default {
        name: 'Home',
        components: {
        },
        data() {
            return {
                value: '',
                wxurl: '',
                bulletinList: [/*{ id: 0, text: '端午节放假通知' }, { id: 1, text: '源起蔚来2022年重点工作计划' }, */{ id: 2, text: '明日之星，优秀员工表彰' }],


            };
        },
        methods: {
            toBulletin(id) {
                this.$router.push({ path: '/app/bulletin', query: { id: id } })
            }
        },
        mounted() {
            window.$vue = this;
            if (!this.user.WxOpenID) {
                let call = encodeURI('https://wx.gdyqwl.com/api/v1/Wx/BindingUser');
                let state = this.user.UserName;
                this.wxurl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bf2cbccd30adfc6&redirect_uri=${call}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
            }
        }
    }
</script>

<style type="text/less" scoped>

    .custom-title {
        font-size: 16px;
        font-weight: bold;
    }

    .my-swipe {
        background: url(../../assets/gg.jpg) no-repeat;
        background-size: 100%;
    }

        .my-swipe .van-swipe-item {
            color: #fff;
            font-size: 20px;
            text-align: center;
            height: 150px;
            display: flex;
        }

        .my-swipe .van-multi-ellipsis--l2 {
            width: 60%;
            font-size: 20px;
            font-weight: bold;
            margin: auto;
        }
</style>