<template>
    <div class="MyWeekWorkReport">
        <van-tabs v-model="activeTab" safe-area-inset-bottom>
            <van-tab title="我的周报">
                <van-empty v-show="myWeekWorkReportList.length==0 && !loading" description="您本周还未填写周报哦!" />
                <van-list v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad">
                    <!--<van-cell-group v-for="weekReport in myWeekWorkReportList" :key="item.ID">
                        <van-cell v-for="item in weekReport.Detail" :key="item.ID">
                            <template #title>
                                {{new Date(Date.parse(item.WeekDay.replace(/-/g, "/"))).format('yyyy-MM-dd')}}周报
                            </template>
                            <template #label>
                                <p>
                                    本周工作内容:<br />{{item.WorkThisWeek}}
                                </p>
                                <p v-show="item.TomorrowPlan">
                                    下周工作计划:<br />{{item.WorkNextWeek}}
                                </p>
                                <p v-show="item.Question">
                                    问题点:<br />{{item.Question}}
                                </p>
                            </template>
                        </van-cell>
                    </van-cell-group>-->
                    <van-form>
                        <div class="add_title">{{addForm.ID>0?'调整':'新增'}}周报</div>
                        <van-collapse v-model="activeName">
                            <van-collapse-item v-for="(item,index) in addForm.Detail" :key="item.ID" :title="'工作内容'+(index+1)">
                                <van-field readonly
                                           clickable
                                           label="项目"
                                           :value="item.ProjectName"
                                           placeholder="选择项目"
                                           @click="showPicker = true;rowIndex=index;" />
                                <van-field v-model="item.Content"
                                           rows="3"
                                           autosize
                                           label="本周工作内容"
                                           type="textarea"
                                           maxlength="500"
                                           placeholder="本周工作内容" :rules="[{ required: true, message: '本周工作内容必须填写哦!' }]"
                                           show-word-limit />
                                <van-cell title="耗时(h)">
                                    <van-stepper v-model="item.TimeSpent" step="1" min="0" />
                                </van-cell>
                                <van-cell title="进度(%)" style="text-align:left !important;">
                                    <van-stepper v-model="item.Progress" step="10" min="0" />
                                </van-cell>
                                <div style="text-align:center;">
                                    <van-button icon="clear" type="danger" size="small" @click="delRow(index)">移除</van-button>
                                </div>
                            </van-collapse-item>
                        </van-collapse>
                        <div style="text-align:center;">
                            <van-button icon="plus" type="primary" size="small" @click="addRow">增加一条</van-button>
                        </div>
                        <van-field v-model="addForm.WorkNextWeek" v-if="showNextWeekPlan"
                                   rows="3"
                                   autosize
                                   label="下周工作计划"
                                   type="textarea"
                                   maxlength="500"
                                   placeholder="下周工作计划"
                                   show-word-limit />
                        <van-field v-model="addForm.Question"
                                   rows="3"
                                   autosize
                                   label="问题点"
                                   type="textarea"
                                   maxlength="500"
                                   placeholder="问题点"
                                   show-word-limit />
                        <div style="margin: 16px;">
                            <van-button round block type="info" @click="onSubmit">提交</van-button>
                        </div>
                    </van-form>
                </van-list>
                <van-tabbar v-model="active" safe-area-inset-bottom placeholder>
                    <van-tabbar-item v-if="myWeekWorkReportList.length==0" @click="isEdit=false;dialogFormVisible=true">填写周报</van-tabbar-item>
                </van-tabbar>
            </van-tab>
            <van-tab title="团队周报">
                <van-empty v-show="groupWeekWorkReportList.length==0 && !loading2" description="您的团队本周尚未填写周报哦!" />
                <van-list v-model="loading2"
                          :finished="finished2"
                          finished-text="没有更多了"
                          @load="onLoad2">
                    <van-cell v-for="(item,i) in groupWeekWorkReportList" :key="item.ID">
                        <template #title>
                            {{i+1}},{{item.CreateUser}}({{new Date(Date.parse(item.WeekDay.replace(/-/g, "/"))).format('yyyy-MM-dd')}})周报
                        </template>
                        <template #label>
                            <p>
                                本周工作内容:<br />{{item.WorkThisWeek}}
                            </p>
                            <p v-show="item.TomorrowPlan">
                                下周工作计划:<br />{{item.WorkNextWeek}}
                            </p>
                            <p v-show="item.Question">
                                问题点:<br />{{item.Question}}
                            </p>
                        </template>
                    </van-cell>
                </van-list>
                <van-tabbar v-model="activeGroup" safe-area-inset-bottom placeholder>
                    <van-tabbar-item @click="prevWeek">上一周</van-tabbar-item>
                    <van-tabbar-item @click="currWeek">本周</van-tabbar-item>
                    <van-tabbar-item @click="nextWeek">下一周</van-tabbar-item>
                </van-tabbar>
            </van-tab>
        </van-tabs>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar
                        :columns="projectList"
                        value-key="value"
                        @cancel="showPicker = false"
                        @confirm="onConfirm" />
        </van-popup>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    import { Picker } from 'vant';
    import { Dialog } from 'vant';
    export default {
        name: 'MyWorkThisWeekReport',
        components: {
        },
        data() {
            return {
                isEdit: false,
                showNextWeekPlan: false,
                queryForm: {
                    PageIndex: 1,
                    PageSize: 6,
                    Data: {
                        CreateUser: '',
                        WeekDay: '',
                    }
                },
                queryForm2: {
                    PageIndex: 1,
                    PageSize: 6,
                    Data: {
                        CreateUser: '',
                        WeekDay: '',
                    }
                },
                addForm: {
                    ID: 0,
                    WorkThisWeek: '',
                    WorkNextWeek: '',
                    Question: '',
                    Detail: [
                        {
                            Id: 0,
                            ProjectCode: "",
                            Content: "",
                            TimeSpent: 0,
                            Progress: 0,
                            WeekReportId: 0,
                        }
                    ]
                },
                activeTab: 0,
                active: 0,
                activeGroup: 1,
                dialogFormVisible: false,
                loading: false,
                loading2: false,
                finished: false,
                finished2: false,
                myWeekWorkReportList: [],
                groupWeekWorkReportList: [],
                projectList: [],
                showPicker: false,
                rowIndex: 0,
                activeName: [],
            };
        },
        methods: {
            getFirstDateOfWeek(theDate) {
                theDate = theDate || new Date();
                theDate.setDate(theDate.getDate() - theDate.getDay()); //
                let firstDateOfWeek = theDate;
                firstDateOfWeek.setDate(firstDateOfWeek.getDate() + 1);
                return firstDateOfWeek;
            },
            onLoad(reload) {

                if (reload) {
                    this.queryForm.PageIndex = 1;
                }
                this.queryForm.Data.CreateUser = this.user.UserName;
                this.$post(this.apiUrl.getWeekWorkReportList, this.queryForm).then((d) => {

                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        if (reload) {
                            this.myWeekWorkReportList = [];
                        }
                        //this.formatString(d.Data);
                        d.Data.Rows.map(r => this.myWeekWorkReportList.push(r));
                    }

                    this.loading = false;

                    if (this.myWeekWorkReportList.length >= d.Data.TotalCounts) {

                        this.finished = true;
                    }
                    this.activeName = this.addForm.Detail.map((panel, index) => index.toString());
                    console.log(this.activeName);
                    if (this.myWeekWorkReportList.length > 0) {
                        this.addForm = this.myWeekWorkReportList[0];
                    }

                    console.log(this.activeName);
                    this.queryForm.PageIndex++;

                });

            },
            onLoad2(reload) {
                if (reload) {
                    this.queryForm2.PageIndex = 1;
                }
                this.queryForm2.Data.CreateUser = '';
                this.$post(this.apiUrl.getWeekWorkReportList, this.queryForm2).then((d) => {

                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        if (reload) {
                            this.groupWeekWorkReportList = [];
                        }
                        d.Data.Rows.map(r => this.groupWeekWorkReportList.push(r));
                    }

                    this.loading2 = false;

                    if (this.groupWeekWorkReportList.length >= d.Data.TotalCounts) {

                        this.finished2 = true;
                    }

                    this.queryForm2.PageIndex++;

                });
            },
            onSubmit() {
                let that = this;
                this.$post(that.addForm.ID > 0 ? that.apiUrl.editWeekWorkReport : that.apiUrl.addWeekWorkReport, that.addForm).then((d) => {
                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        Toast.success('提交成功!');
                        that.dialogFormVisible = false;
                        that.onLoad(true);
                    }
                });
            },
            onConfirm(e) {
                this.addForm.Detail[this.rowIndex].ProjectCode = e.label;
                this.addForm.Detail[this.rowIndex].ProjectName = e.value;
                this.showPicker = false;
                console.log(this.addForm.Detail);
            },
            edit(row) {

                if (row.WeekDay == this.getFirstDateOfWeek().format('yyyy-MM-dd 00:00:00')) {
                    this.isEdit = true;
                    this.addForm.ID = row.ID;
                    this.addForm.WorkThisWeek = row.WorkThisWeek;
                    this.addForm.WorkNextWeek = row.WorkNextWeek;
                    this.addForm.Question = row.Question;
                    this.dialogFormVisible = true;
                }
            },
            delRow(index) {
                Dialog.confirm({
                    message: '是否确认删除这条工作内容'
                })
                    .then(() => {
                        this.addForm.Detail.splice(index, 1);
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            addRow() {
                this.addForm.Detail.push({
                    Id: 0,
                    ProjectCode: "",
                    Content: "",
                    TimeSpent: 0,
                    Progress: 0,
                    WeekReportId: this.addForm.ID
                });
            },
            prevWeek() {
                alert('11');
                let d = new Date(this.queryForm2.Data.WeekDay.replace(/-/g, '/'));
                d.setDate(d.getDate() - 7);
                this.queryForm2.Data.WeekDay = d.format('yyyy-MM-dd');
                this.onLoad2(true);
            },
            currWeek() {
                this.queryForm2.Data.WeekDay = this.getFirstDateOfWeek().format('yyyy-MM-dd');
                this.onLoad2(true);
            },
            nextWeek() {
                let d = new Date(this.queryForm2.Data.WeekDay.replace(/-/g, '/'));
                d.setDate(d.getDate() + 7);
                this.queryForm2.Data.WeekDay = d.format('yyyy-MM-dd');
                this.onLoad2(true);
            },
            getSel() {
                this.$post(this.apiUrl.getProjectSel, {}).then((g) => {
                    if (g.IsError) {
                        this.$message.error(g.ErrorMessage);
                        this.projectList = [];
                    } else {
                        this.projectList = g.Data.map(r => {
                            return { label: r.ProjectCode, value: r.ProjectName }
                        });
                        console.log(this.projectList);
                    }
                });
            },
        },
        mounted() {
            this.queryForm.Data.CreateUser = this.user.UserName;
            this.showNextWeekPlan = true;
            this.queryForm.Data.WeekDay = this.getFirstDateOfWeek().format('yyyy-MM-dd');
            this.queryForm2.Data.WeekDay = this.getFirstDateOfWeek().format('yyyy-MM-dd');
            this.getSel();
            window.$vue = this;
        }

    }
</script>

<style scoped>

    .MyWeekWorkReport {
        height: 100%;
    }

        .MyWeekWorkReport p {
            white-space: pre-wrap;
        }

    .van-tabs {
        height: 100%;
    }

    .add_title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        padding: 15px;
    }

    .MyWeekWorkReport /deep/ .van-tabs__content {
        max-height: calc(100% - 44px);
        overflow-y: auto;
    }

    .cell-class-name .van-cell__value .van-cell__value {
        min-width: 80%;
        text-align: left;
    }
</style>