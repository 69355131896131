<template>
    <div class="MyWorkReport">
        <van-tabs v-model="activeTab" safe-area-inset-bottom>
            <van-tab title="我的日报">
                <van-empty v-show="myWorkReportList.length==0 && !loading" description="您还没有填写日报哦" />
                <van-list v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad">
                    <van-cell v-for="item in myWorkReportList" :key="item.ID" @click="edit(item)">
                        <template #title>
                            {{new Date(Date.parse(item.WorkDay.replace(/-/g, "/"))).format('yyyy-MM-dd')}}日报
                        </template>
                        <template #label>
                            <p>
                                今日工作:<br />{{item.ReportToday}}
                            </p>
                            <p v-show="item.TomorrowPlan">
                                明天计划:<br />{{item.TomorrowPlan}}
                            </p>
                            <p v-show="item.Question">
                                问题点:<br />{{item.Question}}
                            </p>
                        </template>
                    </van-cell>
                </van-list>
                <van-tabbar v-model="active" safe-area-inset-bottom placeholder>
                    <van-tabbar-item @click="isEdit=false;dialogFormVisible=true">填写日报</van-tabbar-item>
                </van-tabbar>
            </van-tab>
            <van-tab title="团队日报">
                <van-empty v-show="groupWorkReportList.length==0 && !loading2" description="您的团队今日还没有填写日报哦" />
                <van-list v-model="loading2"
                          :finished="finished2"
                          finished-text="没有更多了"
                          @load="onLoad2">
                    <van-cell v-for="item in groupWorkReportList" :key="item.ID">
                        <template #title>
                            {{item.CreateUser}}({{new Date(Date.parse(item.WorkDay.replace(/-/g, "/"))).format('yyyy-MM-dd')}})日报
                        </template>
                        <template #label>
                            <p>
                                今日工作:<br />{{item.ReportToday}}
                            </p>
                            <p v-show="item.TomorrowPlan">
                                明天计划:<br />{{item.TomorrowPlan}}
                            </p>
                            <p v-show="item.Question">
                                问题点:<br />{{item.Question}}
                            </p>
                        </template>
                    </van-cell>
                </van-list>
            </van-tab>
        </van-tabs>
        <van-popup v-model="dialogFormVisible" position="bottom" :style="{ height: '70%' }" safe-area-inset-bottom>
            <van-form @submit="onSubmit">
                <div class="add_title">{{isEdit?'调整':'新增'}}今日日报</div>
                <van-field v-model="addForm.ReportToday"
                           rows="3"
                           autosize
                           label="今日工作"
                           type="textarea"
                           maxlength="500"
                           placeholder="今日工作" :rules="[{ required: true, message: '今日工作内容必须填写' }]"
                           show-word-limit />
                <van-field v-model="addForm.TomorrowPlan"
                           rows="3"
                           autosize
                           label="明天计划"
                           type="textarea"
                           maxlength="500"
                           placeholder="明天计划"
                           show-word-limit />
                <van-field v-model="addForm.Question"
                           rows="3"
                           autosize
                           label="问题点"
                           type="textarea"
                           maxlength="500"
                           placeholder="问题点"
                           show-word-limit />
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>

        </van-popup>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    export default {
        name: 'MyWorkReport',
        components: {
        },
        data() {
            return {
                isEdit: false,
                queryForm: {
                    PageIndex: 1,
                    PageSize: 6,
                    Data: {
                        CreateUser: '',
                        Time: [],
                        BeginDay: '',
                        EndDay: '',
                    }
                },
                queryForm2: {
                    PageIndex: 1,
                    PageSize: 6,
                    Data: {
                        CreateUser: '',
                        Time: [],
                        BeginDay: '',
                        EndDay: '',
                    }
                },
                addForm: {
                    ID: 0,
                    ReportToday: '',
                    TomorrowPlan: '',
                    Question: '',
                },
                activeTab: 0,
                active: 0,
                dialogFormVisible: false,
                loading: false,
                loading2: false,
                finished: false,
                finished2: false,
                myWorkReportList: [],
                groupWorkReportList: [],
            };
        },
        methods: {
            onLoad(reload) {

                if (reload) {
                    this.queryForm.PageIndex = 1;
                }
                this.queryForm.Data.CreateUser = this.user.UserName;
                let beginDate = new Date();
                beginDate.setDate(-30);
                this.queryForm.Data.BeginDay = beginDate.format('yyyy-MM-dd')
                this.queryForm.Data.EndDay = new Date().format('yyyy-MM-dd')
                this.$post(this.apiUrl.getWorkReportList, this.queryForm).then((d) => {

                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        if (reload) {
                            this.myWorkReportList = [];
                        }
                        d.Data.Rows.map(r => this.myWorkReportList.push(r));
                    }

                    this.loading = false;

                    if (this.myWorkReportList.length >= d.Data.TotalCounts) {

                        this.finished = true;
                    }

                    this.queryForm.PageIndex++;

                });

            },
            onLoad2() {
                let beginDate = new Date();
                beginDate.setDate(-6);
                this.queryForm2.Data.BeginDay = beginDate.format('yyyy-MM-dd')
                this.queryForm2.Data.EndDay = new Date().format('yyyy-MM-dd')
                this.queryForm2.Data.CreateUser = '';
                this.$post(this.apiUrl.getWorkReportList, this.queryForm2).then((d) => {

                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        d.Data.Rows.map(r => this.groupWorkReportList.push(r));
                    }

                    this.loading2 = false;

                    if (this.groupWorkReportList.length >= d.Data.TotalCounts) {

                        this.finished2 = true;
                    }

                    this.queryForm2.PageIndex++;

                });
            },
            onSubmit() {
                let that = this;
                this.$post(that.isEdit ? that.apiUrl.editWorkReport : that.apiUrl.addWorkReport, that.addForm).then((d) => {
                    if (d.IsError) {
                        Toast.fail(d.ErrorMessage);
                    } else {
                        Toast.success('提交成功!');
                        that.dialogFormVisible = false;
                        that.onLoad(true);
                    }
                });
            },
            edit(row) {

                if (row.WorkDay == new Date().format('yyyy-MM-dd 00:00:00')) {
                    this.isEdit = true;
                    this.addForm.ID = row.ID;
                    this.addForm.ReportToday = row.ReportToday;
                    this.addForm.TomorrowPlan = row.TomorrowPlan;
                    this.addForm.Question = row.Question;
                    this.dialogFormVisible = true;
                }

            },

        },
        mounted() {
            this.queryForm.Data.CreateUser = this.user.UserName;
            let begin = new Date().format('yyyy-MM-01 00:00:00');
            let end = new Date(begin);
            end.setMonth(end.getMonth() + 1);
            end.setSeconds(-1);
            this.queryForm.Data.Time = [begin, end.format('yyyy-MM-dd 23:59:59')];
            window.$vue = this;
        }

    }
</script>

<style scoped>

    .MyWorkReport {
        height: 100%;
    }

        .MyWorkReport p {
            white-space: pre-wrap;
        }



    .van-tabs {
        height: 100%;
    }

    .add_title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        padding: 15px;
    }

    .MyWorkReport /deep/ .van-tabs__content {
        max-height: calc(100% - 44px);
        overflow-y: auto;
    }
</style>